<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-square" />Pilar
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable
          class="table-outline"
          hover
          :items="pilares"
          :fields="CamposTabela"
          head-color="light"
          sorter
          pagination
          table-filter
          :items-per-page="6"
          :loading="carregandoPilar"
          :sorterValue="{ column: 'id', asc: true }"
        >
          <td slot="acoes" class="text-center" slot-scope="{item}">
            <CButtonGroup>
              <CButton color="danger" size="sm" @click="removerPilar(item)">
                <CIcon name="cil-trash" />
              </CButton>
              <CButton color="info" size="sm" @click="editarPilar(item)">
                <CIcon name="cil-folder-open" />
              </CButton>
            </CButtonGroup>
          </td>
          <template slot="no-items-view">
            <div class="text-center my-5">
              <h2 v-if="!carregandoPilar">Nenhum pilar encontrado!!!</h2>
            </div>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CButtonGroup class="float-right">
          <CButton color="info" size="sm" @click="importarPilar">
            <CIcon name="cil-folder-open"></CIcon>&nbsp;Importar Pilar
          </CButton>
          <CButton color="success" size="sm" @click="novoPilar">
            <CIcon name="cil-notes"></CIcon>&nbsp;Novo Pilar
          </CButton>
        </CButtonGroup>
      </CCardFooter>
    </CCard>
    <CModal
      :show.sync="formularioCadastroPilar"
      size="lg"
      addContentClasses="card"
      :closeOnBackdrop="false"
      ref="formularioPilar"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Cadastro de Pilar
          <CButtonClose @click="formularioCadastroPilar = false" buttonClasses="text-muted close" />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <SBFormularioPilar @pilarSalvo="pilarSalvoSucesso"></SBFormularioPilar>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="salvarPilar">
            <CIcon name="cil-save"></CIcon>&nbsp;Salvar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>

    <CModal
      :show.sync="formularioImportarDxf"
      size="lg"
      addContentClasses="card"
      :closeOnBackdrop="false"
      ref="formularioPilarDxf"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Importar Pilar DXF
          <CButtonClose @click="formularioImportarDxf = false" buttonClasses="text-muted close" />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <SBImportarPilarDXF
            @importacaoConcluida="acaoPilarImportado"
            @pilaresImportadosSalvos="acaoPilarImportadoSalvo"
          ></SBImportarPilarDXF>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButtonGroup v-if="acaoFormularioImportar==0" class="float-right">
            <CButton
              color="warning"
              size="sm"
              v-if="acaoFormularioImportar==0"
              @click="reinciarFormularioImportacao"
            >
              <CIcon name="cil-loop"></CIcon>&nbsp;Reiniciar
            </CButton>
            <CButton
              color="success"
              size="sm"
              @click="gerarAcaoFormularioImportar('salvarPilaresImportado')"
            >
              <CIcon name="cil-save"></CIcon>&nbsp;Salvar
            </CButton>
          </CButtonGroup>
          <CButton
            color="info"
            size="sm"
            class="float-right"
            v-if="acaoFormularioImportar==1"
            @click="gerarAcaoFormularioImportar('processarPlantaDxf')"
          >
            <CIcon name="cil-calculator"></CIcon>&nbsp;Processar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
import SBFormularioPilar from "./FormularioPilar";
import SBImportarPilarDXF from "./ImportarPilarDXF";
import Loader from "./../Componentes/loader";
import Axios from "axios";
import Meta from "./../../shared/meta-dados";
export default {
  name: "Pilar",
  components: {
    SBFormularioPilar,
    SBImportarPilarDXF,
    Loader
  },
  data() {
    return {
      carregandoPilar: false,
      formularioCadastroPilar: false,
      formularioImportarDxf: false,
      pilares: [],
      mensagens: [],
      requisicaoAberta: false,
      acaoFormularioImportar: 1, //1 para processar, 0 para salvar
      CamposTabela: [
        {
          key: "idLinha",
          label: "#",
          _classes: "text-center",
          sorter: true
        },
        {
          key: "identificacao",
          label: "Pilar",
          _classes: "text-center",
          sorter: true
        },
        {
          key: "largura",
          label: "Largura",
          _classes: "text-center",
          sorter: false
        },
        {
          key: "altura",
          label: "Altura",
          _classes: "text-center",
          sorter: false
        },
        {
          key: "x",
          label: "X(m)",
          _classes: "text-center",
          sorter: false
        },
        {
          key: "y",
          label: "Y(m)",
          _classes: "text-center",
          sorter: false
        },
        {
          key: "angulo",
          label: "Ângulo",
          _classes: "text-center",
          sorter: false
        },
        { key: "acoes", label: "Ações", sorter: false, _classes: "text-center" }
      ]
    };
  },
  methods: {
    carregarPilares() {
      let url = "/projeto/" + this.$route.params.projetoID + "/pilar/";
      this.carregandoPilar = true;
      Axios.get(url)
        .then(resposta => {
          if (resposta.status == 200) {
            this.pilares = resposta.data.retorno.map((pilar, index) => {
              pilar.idLinha = index + 1;
              return pilar;
            });
          }
        })
        .catch(erro => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Não foi possível carregar os pilares",
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.carregandoPilar = false;
        });
    },
    salvarPilar() {
      this.$eventoHub.$emit("salvarPilar", "");
    },
    novoPilar() {
      this.$eventoHub.$emit("pilar", {
        id: false,
        identificacao: "",
        largura: null,
        altura: null,
        angulo: null,
        x: null,
        y: null,
        casos: []
      });
      this.formularioCadastroPilar = true;
    },
    editarPilar(pilar) {
      this.$eventoHub.$emit("pilar", pilar);
      this.formularioCadastroPilar = true;
    },
    pilarSalvoSucesso(retorno) {

      if (retorno.acao !== undefined) {
        this.$eventoHub.$emit("adicionarMensagemAviso", {
          mensagem: "O pilar foi editado com súcesso!",
          classe: "bg-success"
        });
        this.formularioCadastroPilar = false;
        return;
      }
      this.$eventoHub.$emit("adicionarMensagemAviso", {
        mensagem: "O pilar foi salvo com súcesso!",
        classe: "bg-success"
      });
      this.novoPilar();
    },
    removerPilar(pilar) {
      let idProjeto = this.$route.params.projetoID;
      let url = "/projeto/" + idProjeto + "/pilar/" + pilar.id;

      this.$swal({
        title: "Atenção",
        icon: "warning",
        text: Meta.obtenhaMensagem(1012, "pt-br"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.requisicaoAberta = true;
          Axios.delete(url)
            .then(resposta => {
              if (resposta.status) {
                this.$eventoHub.$emit("adicionarMensagemAviso", {
                  mensagem: "O pilar foi excluído!",
                  classe: "bg-success"
                });
                this.carregarPilares();
              }
            })
            .catch(error => {
              this.$eventoHub.$emit("adicionarMensagemAviso", {
                mensagem: "Não foi possível excluir o pilar!",
                classe: "bg-danger"
              });
            })
            .finally(() => {
              this.requisicaoAberta = false;
            });
        }
      });
    },
    importarPilar() {
      this.formularioImportarDxf = true;
    },
    gerarAcaoFormularioImportar(acao) {
      //this.acaoFormularioImportar = this.acaoFormularioImportar == 1 ? 0 : 1;
      this.$eventoHub.$emit(acao, true);
    },
    reinciarFormularioImportacao() {
      this.$swal({
        title: "Atenção",
        html:
          "Ao reiniciar o formulário, <b>você perde a importação atual</b>. Deseja continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          this.gerarAcaoFormularioImportar("reiniciarFormularioImportacao");
          this.acaoFormularioImportar = 1;
        }
      });
    },
    acaoPilarImportado(retorno) {
      if (typeof retorno == "boolean") {
        this.acaoFormularioImportar = 0;
      } else {
        this.acaoFormularioImportar = 1;
      }
    },
    acaoPilarImportadoSalvo(retorno) {
      if (retorno) {
        this.formularioImportarDxf = false;
        this.gerarAcaoFormularioImportar("reiniciarFormularioImportacao");
      }
    }
  },
  watch: {
    formularioCadastroPilar(novoValor, velhoValor) {
      let tempoEspera;
      if (novoValor) {
        tempoEspera = setTimeout(() => {
          this.$eventoHub.$emit("shown", this.$refs["formularioPilar"]);
        }, 450);
      } else {
        this.carregarPilares();
      }
    },
    formularioImportarDxf(novoValor, velhoValor) {
      if (!novoValor) this.carregarPilares();
    }
  },
  created() {
    this.carregarPilares();
  }
};
</script>

<style>
</style>
