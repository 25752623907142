<template>
  <div>
    <loader :loading="requisicaoAberta"></loader>
    <CForm>
      <CRow>
        <CCol sm="12">
          <CRow>
            <CCol sm="4">
              <img src="@/views/Pilar/tela-cadastro-pilar.svg" width="100%" />
            </CCol>
            <CCol sm="8">
              <CRow>
                <CCol sm="4">
                  <CInput
                    ref="identificacaopilar"
                    label="Pilar"
                    :value.sync="$v.pilarManipulado.identificacao.$model"
                    :isValid="verificarCampo('identificacao', 'pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem(2004)"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Largura (a)"
                    type="number"
                    min="0"
                    :value.sync="$v.pilarManipulado.largura.$model"
                    :isValid="verificarCampo('largura','pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem('largura',{required : 2005, isPositive : 2006}, $v.pilarManipulado)"
                    append="m"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Altura (b)"
                    type="number"
                    min="0"
                    :value.sync="$v.pilarManipulado.altura.$model"
                    :isValid="verificarCampo('altura','pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem('altura',{required : 2007, isPositive : 2008}, $v.pilarManipulado)"
                    append="m"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <CInput
                    label="Ângulo (ø)"
                    type="number"
                    :value.sync="$v.pilarManipulado.angulo.$model"
                    :isValid="verificarCampo('angulo','pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem(2016)"
                    append="º"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Localização X"
                    type="number"
                    :value.sync="$v.pilarManipulado.x.$model"
                    :isValid="verificarCampo('x','pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem(2016)"
                    append="m"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Localização Y"
                    type="number"
                    :value.sync="$v.pilarManipulado.y.$model"
                    :isValid="verificarCampo('y','pilarManipulado')"
                    :invalidFeedback="obtenhaMensagem(2016)"
                    append="m"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="3">
              <CInput
                ref="titulocaso"
                label="Descrição"
                :value.sync="$v.caso.titulo.$model"
                :isValid="verificarCampo('titulo','caso')"
                :invalidFeedback="obtenhaMensagem(2010)"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                label="Fz"
                type="number"
                :value.sync="$v.caso.forcaz.$model"
                :isValid="verificarCampo('forcaz','caso')"
                :invalidFeedback="obtenhaMensagem(2011)"
                append="kN"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                label="Fx"
                type="number"
                :value.sync="$v.caso.forcax.$model"
                :isValid="verificarCampo('forcax','caso')"
                :invalidFeedback="obtenhaMensagem(2016)"
                append="kN"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                label="Fy"
                type="number"
                :value.sync="$v.caso.forcay.$model"
                :isValid="verificarCampo('forcay','caso')"
                :invalidFeedback="obtenhaMensagem(2016)"
                append="kN"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="3">
              <CInput
                label="Mx"
                type="number"
                :value.sync="$v.caso.momentox.$model"
                :isValid="verificarCampo('momentox','caso')"
                :invalidFeedback="obtenhaMensagem(2016)"
                append="kN.m"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                label="My"
                type="number"
                :value.sync="$v.caso.momentoy.$model"
                :isValid="verificarCampo('momentoy','caso')"
                :invalidFeedback="obtenhaMensagem(2016)"
                append="kN.m"
              />
            </CCol>
            <CCol sm="2">
              <br />
              <br />
              <CInputCheckbox label="Vento" :checked.sync="caso.vento" />
            </CCol>
            <CCol sm="2">
              <br />
              <br />
              <CButton color="info" size="sm" class="float-left" @click="adicionarCaso">Adicionar</CButton>
            </CCol>
          </CRow>
          <CAlert color="danger" :show="($v.pilarManipulado.casos.$error)">{{obtenhaMensagem(2009)}}</CAlert>
        </CCol>
      </CRow>
      <hr />
      <CDataTable small :fields="camposTabelaCaso" :items="$v.pilarManipulado.casos.$model">
        <td slot="solo" slot-scope="{item}">{{solos[item.solo]}}</td>
        <td slot="vento" slot-scope="{item}">{{(item.vento ? "Sim" : "Não")}}</td>
        <td slot="acoes" slot-scope="{item}">
          <CButtonGroup size="sm">
            <CButton color="info" @click="editarCaso(item.idControle)">
              <CIcon name="cil-pencil"></CIcon>
            </CButton>
            <CButton color="danger" @click="excuirCaso(item.idControle)">
              <CIcon name="cil-trash"></CIcon>
            </CButton>
          </CButtonGroup>
        </td>
        <template slot="no-items-view">
          <div class="text-center my-5">
            <h3>Nenhum caso cadastrado!!!</h3>
          </div>
        </template>
      </CDataTable>
    </CForm>
  </div>
</template>

<script>
const isArray = value => value.length != undefined && value.length > 0;
const isPositive = value => parseFloat(value) > 0;
import { required, decimal } from "vuelidate/lib/validators";
import Axios from "axios";
import Loader from "./../Componentes/loader";
import mixin from "./../../shared/mixin";
export default {
  name: "SBFormularioPilar",
  mixins: [mixin],
  components: { Loader },
  props: {},
  data() {
    return {
      pilarManipulado: {
        identificacao: "",
        largura: null,
        altura: null,
        x: 0,
        y: 0,
        angulo: 0,
        casos: [[]]
      },
      casos: [],
      caso: {
        titulo: "",
        forcaz: null,
        forcax: 0,
        forcay: 0,
        momentox: 0,
        momentoy: 0,
        vento: false
      },
      camposTabelaCaso: [
        { key: "titulo", label: "Descrição" },
        { key: "forcaz", label: "Fz (kN)" },
        { key: "forcax", label: "Fx (kN)" },
        { key: "forcay", label: "Fy (kN)" },
        { key: "momentox", label: "Mx (kN.m)" },
        { key: "momentoy", label: "My (kN.m)" },
        { key: "vento", label: "Vento" },
        { key: "acoes", label: "Ações" }
      ],
      requisicaoAberta: false
    };
  },
  validations: {
    pilarManipulado: {
      identificacao: { required },
      largura: { required, decimal, isPositive },
      altura: { required, decimal, isPositive },
      x: { decimal },
      y: { decimal },
      angulo: { decimal },
      casos: { isArray }
    },
    caso: {
      titulo: { required },
      forcaz: { required, decimal },
      forcax: { decimal },
      forcay: { decimal },
      momentox: { decimal },
      momentoy: { decimal }
    }
  },
  methods: {
    editarCaso(idControle) {
      this.caso = this.pilarManipulado.casos[idControle];
      this.caso.idControle = null;
      this.excuirCaso(idControle);
    },
    excuirCaso(idControle) {
      this.pilarManipulado.casos.splice(idControle, 1);
      this.pilarManipulado.casos.forEach((element, index) => {
        this.pilarManipulado.casos[index].idControle = index;
      });
    },
    adicionarCaso() {
      this.$v.caso.$touch();
      if (this.$v.caso.$invalid) {
        return;
      }
      let idControle =
        this.pilarManipulado.casos.length !== undefined
          ? this.pilarManipulado.casos.length
          : 0;
      this.caso.idControle = idControle;
      this.pilarManipulado.casos.push(this.caso);
      this.caso = this.reiniciarFormularioCaso();
      this.$v.caso.$reset();
      this.$refs["titulocaso"].$el.children[1].focus();
    },
    reiniciarFormularioCaso() {
      return {
        titulo: "",
        forcaz: null,
        forcax: 0,
        forcay: 0,
        momentox: 0,
        momentoy: 0,
        vento: false
      };
    },
    salvarPilar() {
      this.$v.pilarManipulado.$touch();
      if (this.$v.pilarManipulado.$invalid) {
        return;
      }
      this.requisicaoAberta = true;
      let idProjeto = this.$route.params.projetoID;
      let url = this.pilarManipulado.id
        ? "/projeto/" + idProjeto + "/pilar/" + this.pilarManipulado.id
        : "/projeto/" + idProjeto + "/pilar/";
      Axios.post(url, this.pilarManipulado)
        .then(resposta => {
          if (resposta.status) {
            this.$emit("pilarSalvo", {
              acao: this.pilarManipulado.id,
              pilar: resposta.data.retorno
            });
          }
        })
        .catch(error => {
          this.$swal(
            "Erro",
            this.gerarMenssagemDeErro(error.response.data.retorno)
          );
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    carregarPilarFormulario(pilar) {
      let pilarImportado = Object.assign({}, pilar);
      this.pilarManipulado = Object.assign(
        {},
        this.pilarManipulado,
        pilarImportado
      );
      this.$v.pilarManipulado.$reset();
      this.$v.caso.$reset();
    },

    formularioAberto(modal) {
      if (modal.$el.children[0].classList.contains("show")) {
        this.$refs["identificacaopilar"].$el.children[1].focus();
      }
    }
  },
  created() {
    this.$eventoHub.$on("salvarPilar", this.salvarPilar);
    this.$eventoHub.$on("pilar", this.carregarPilarFormulario);
    this.$eventoHub.$on("shown", this.formularioAberto);
  },
  mounted() {},
  destroyed() {
    this.$eventoHub.$off("salvarPilar", this.salvarPilar);
    this.$eventoHub.$off("pilar", this.carregarPilarFormulario);
    this.$eventoHub.$off("shown", this.formularioAberto);
  }
};
</script>

<style>
</style>
