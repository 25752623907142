<template>
  <div>
    <loader :loading="requisicaoAberta"></loader>
    <CForm v-if="!tabelaPilarImportado.length">
      <CRow>
        <CCol sm="12">
          <CRow>
            <CCol sm="12">
              <CInputFile
                custom
                placeholder="Abrir um arquivo, ou arraste aqui..."
                accept=".dxf, .DXF, .Dxf"
                addWrapperClasses="form-group"
                @change="parseDXF"
              ></CInputFile>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="4">
              <CSelect
                label="Layer Pilar"
                :options="optionLayer"
                :value.sync="arquivoDxf.layerPilar"
              ></CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect
                label="Layer Titulo"
                :options="optionLayer"
                :value.sync="arquivoDxf.layerTitulo"
              ></CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect
                label="Layer Tabela"
                :options="optionLayer"
                :value.sync="arquivoDxf.layerTabela"
              ></CSelect>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="12">
              <h6>Unidades do arquivo de importação</h6>
            </CCol>
            <CCol sm="4">
              <CSelect label="Unidade" :options="optionMedida" :value.sync="fatorMedida"></CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect label="Força" :options="optionForca" :value.sync="fatorForca"></CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect label="Momentos" :options="optionMomento" :value.sync="fatorMomento"></CSelect>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CForm>
    <CRow v-if="tabelaPilarImportado.length">
      <CCol sm="12">
        <CDataTable :items="tabelaPilarImportado" :fields="camposTabela" hover>
          <template #exibir="{item, index}">
            <td class="py-2">
              <CButton
                @click="alterarExibicaoDetalhes(index)"
                color="info"
                variant="outline"
                square
                size="sm"
              >{{detalhes.includes(index) ? 'Ocultar' : 'Exibir'}}</CButton>
            </td>
          </template>
          <td slot="largura" slot-scope="{item}">{{item.largura | precisao(2)}}</td>
          <td slot="altura" slot-scope="{item}">{{item.altura | precisao(2)}}</td>
          <td slot="x" slot-scope="{item}">{{item.x | precisao(2)}}</td>
          <td slot="y" slot-scope="{item}">{{item.y | precisao(2)}}</td>
          <td slot="angulo" slot-scope="{item}">{{item.angulo | precisao(2)}}</td>
          <template #details="{item, index}">
            <CCol sm="12">
              <CCollapse :show="detalhes.includes(index)">
                <CDataTable :items="item.casos" :fields="camposCasosDetalhes"></CDataTable>
              </CCollapse>
            </CCol>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DxfParser from "dxf-parser";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import Axios from "axios";
export default {
  name: "SBImportarPilarDXF",
  mixins: [mixin],
  components: { Loader },
  data() {
    return {
      requisicaoAberta: false,
      arquivoDxf: {
        arquivo: "",
        layerPilar: 0,
        layerTitulo: 0,
        layerTabela: 0
      },
      optionLayer: [],
      tabelaPilarImportado: [],
      tabelaPilarImportadoOriginal: [],
      fatorMedida: 1,
      fatorForca: 1,
      fatorMomento: 1,
      optionMedida: [
        { value: 1, label: "Metro" },
        { value: 1 / 100, label: "Centimetro" },
        { value: 1 / 1000, label: "Milimetro" }
      ],
      optionForca: [
        { value: 1, label: "kN" },
        { value: 10, label: "tf" },
        { value: 100, label: "kgf" }
      ],
      optionMomento: [
        { value: 1, label: "kN.m" },
        { value: 100, label: "kN.cm" },
        { value: 10, label: "tf.m" },
        { value: 1000, label: "tf.cm" },
        { value: 100, label: "kgf.m" },
        { value: 10000, label: "kgf.cm" }
      ],
      camposTabela: [
        { key: "identificacao", label: "Pilar" },
        { key: "largura", label: "Largura(m)" },
        { key: "altura", label: "Altura(m)" },
        { key: "x", label: "x(m)" },
        { key: "y", label: "y(m)" },
        { key: "angulo", label: "Ângulo" },
        { key: "exibir", label: "Casos" }
      ],
      camposCasosDetalhes: [
        { key: "titulo", label: "Caso" },
        { key: "forcaz", label: "Fz (kN.m)" },
        { key: "forcax", label: "Fx (kN.m)" },
        { key: "forcay", label: "Fy (kN.m)" },
        { key: "momentox", label: "Mx (kN.m)" },
        { key: "momentoy", label: "My (kN.m)" }
      ],
      detalhes: []
    };
  },
  methods: {
    parseDXF(event) {
      this.arquivoDxf.arquivo = event[0];
      let ext = this.arquivoDxf.arquivo.name.split(".");
      ext = ext[1].toLowerCase();
      if (ext === "dxf") this.gerarOpcoesDxf(event[0]);
    },
    gerarOpcoesDxf(file) {
      var $this = this;
      let arquivo = new FileReader();
      arquivo.onload = function() {
        let dxf = arquivo.result;
        let parser = new DxfParser();
        let elementos = parser.parseSync(dxf);
        let option = [];
        Object.keys(elementos.tables.layer.layers).forEach(function(layer) {
          option.push({ value: layer, label: layer });
        });
        $this.optionLayer = option;
      };
      arquivo.readAsText(file);
    },
    processarPlantaDxf() {
      let idProjeto = this.obtenhaIdProjeto();
      let formData = new FormData();
      formData.append("arquivo", this.arquivoDxf.arquivo);
      formData.append("layerPilar", this.arquivoDxf.layerPilar);
      formData.append("layerTitulo", this.arquivoDxf.layerTitulo);
      formData.append("layerTabela", this.arquivoDxf.layerTabela);
      this.requisicaoAberta = true;
      Axios.post("projeto/" + idProjeto + "/pilar/processarDxf", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(response => {
          try {
            if (response.status === 200) {
              this.tabelaPilarImportado = this.processarTabelaImportada(
                response.data.retorno
              );
            }
            this.$emit("importacaoConcluida", true);
          } catch (e) {
            this.$emit("importacaoConcluida", e);
            this.$swal("Erro", e.name + ": " + e.message);
          }
        })
        .catch(error => {
          this.$swal("Erro", error.response.data.retorno);
          this.$emit("importacaoConcluida", error);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    salvarPilaresImportado() {
      let idProjeto = this.obtenhaIdProjeto();
      let vm = this;
      this.requisicaoAberta = true;
      Axios.post(
        "/projeto/" + idProjeto + "/pilar/importado/",
        this.tabelaPilarImportado
      )
        .then(async resposta => {
          if (resposta.status == 201) {
            let pilaresOk = resposta.data.retorno.filter(pilar => {
              return pilar.status;
            });
            let pilaresErro = resposta.data.retorno.filter(pilar => {
              return !pilar.status;
            });
            let numeroPilaresOk = pilaresOk.length;
            let msgConcatenar = numeroPilaresOk
              ? '<p class="text-primary"><b>Foram salvos ' +
                numeroPilaresOk +
                " pilares</b></p>"
              : "";
            let msgErros = pilaresErro.map(pilar => {
              return (
                '<p class="text-danger"><b>' +
                pilar.pilar.identificacao +
                ":</b> " +
                vm.obtenhaMensagem(pilar.erros) +
                "</p>"
              );
            });
            msgConcatenar += msgErros.join("");
            await this.$swal("Atenção", msgConcatenar);
            this.$emit("pilaresImportadosSalvos", true);
          }
        })
        .catch(error => {
          this.$swal("Atenção", error.response.data.retorno);
          this.$emit("pilaresImportadosSalvos", false);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    processarTabelaImportada(dados) {
      let tabelaImportado = [];
      let pilares = Object.keys(dados);
      let pilaresErro = pilares.filter(pilar => {
        return Object.keys(dados[pilar]).length < 6;
      });
      let pilaresOk = pilares.filter(pilar => {
        return Object.keys(dados[pilar]).length >= 6;
      });
      pilaresOk.forEach(pilar => {
        let pilarObjeto = {
          identificacao: dados[pilar][0],
          x: parseFloat(dados[pilar][1]),
          y: parseFloat(dados[pilar][2]),
          largura: dados[pilar][3].length
            ? parseFloat(dados[pilar][3][0])
            : 0.2,
          altura: dados[pilar][3].length ? parseFloat(dados[pilar][3][1]) : 0.2,
          secao: dados[pilar][4],
          angulo: parseFloat(dados[pilar][5]) ? parseFloat(dados[pilar][5]) : 0,
          casos: this.criarTabelaCasosPilarImportado(dados[pilar][6])
        };
        tabelaImportado.push(this.compatibilizarUnidades(pilarObjeto));
      });
      if (pilaresErro.length) {
        let msgConcatenar = ": Não foi possível importar! </br>";
        let msg = pilaresErro.join(msgConcatenar) + msgConcatenar;
        this.$swal("Atenção", msg);
      }
      return tabelaImportado;
    },
    criarTabelaCasosPilarImportado(casos) {
      let tabelaCasos = [];
      Object.keys(casos).forEach(caso => {
        let casoObjeto = {
          titulo: casos[caso].desc,
          forcaz: casos[caso].hasOwnProperty("Fz")
            ? parseFloat(casos[caso].Fz)
            : 0,
          momentox: casos[caso].hasOwnProperty("Mx")
            ? parseFloat(casos[caso].Mx)
            : 0,
          momentoy: casos[caso].hasOwnProperty("My")
            ? parseFloat(casos[caso].My)
            : 0,
          forcax: casos[caso].hasOwnProperty("Hx")
            ? parseFloat(casos[caso].Hx)
            : 0,
          forcay: casos[caso].hasOwnProperty("Hy")
            ? parseFloat(casos[caso].Hy)
            : 0
        };
        tabelaCasos.push(casoObjeto);
        return tabelaCasos;
      });
      return tabelaCasos;
    },
    compatibilizarUnidades(pilar) {
      pilar.largura = pilar.largura * this.fatorMedida;
      pilar.altura = pilar.altura * this.fatorMedida;
      pilar.x = pilar.x * this.fatorMedida;
      pilar.y = pilar.y * this.fatorMedida;

      pilar.casos.forEach(caso => {
        caso.momentox =
          Math.round(caso.momentox * this.fatorMomento * 100) / 100;
        caso.momentoy =
          Math.round(caso.momentoy * this.fatorMomento * 100) / 100;
        caso.forcaz = Math.round(caso.forcaz * this.fatorForca * 100) / 100;
        caso.forcax = Math.round(caso.forcax * this.fatorForca * 100) / 100;
        caso.forcay = Math.round(caso.forcay * this.fatorForca * 100) / 100;
      });
      return pilar;
    },
    alterarExibicaoDetalhes(index) {
      const position = this.detalhes.indexOf(index);
      position !== -1
        ? this.detalhes.splice(position, 1)
        : this.detalhes.push(index);
    },
    reiniciarFormularioImportacao() {
      this.fatorMomento = this.fatorForca = this.fatorMedida = 1;
      this.tabelaPilarImportado = [];
      this.arquivoDxf = Object.assign({}, this.arquivoDxf, {
        arquivo: "",
        layerPilar: 0,
        layerTitulo: 0,
        layerTabela: 0
      });
      this.optionLayer = [];
    }
  },
  created: function() {
    this.$eventoHub.$on("processarPlantaDxf", this.processarPlantaDxf);
    this.$eventoHub.$on("salvarPilaresImportado", this.salvarPilaresImportado);
    this.$eventoHub.$on(
      "reiniciarFormularioImportacao",
      this.reiniciarFormularioImportacao
    );
  },
  destroyed: function() {
    this.$eventoHub.$off("processarPlantaDxf", this.processarPlantaDxf);
    this.$eventoHub.$off("salvarPilaresImportado", this.salvarPilaresImportado);
    this.$eventoHub.$off(
      "reiniciarFormularioImportacao",
      this.reiniciarFormularioImportacao
    );
  }
};
</script>

<style>
.c-dark-theme select option {
  background-color: rgba(255, 255, 255, 0.1);
}
.c-dark-theme .form-control > option {
  color: black;
}
</style>
